import FormHandlerHelpers from "./FormHandlerHelpers";

export default class OccupancyTypeFormHandler{
    constructor(pageContent) {
        this.pageContent = pageContent;
        this.render()
    }
    render() {
        FormHandlerHelpers.bindToAllOnChange(this.pageContent, '.lease-extendable-yes',
            this.handleLeaseExtendableClick)
        FormHandlerHelpers.bindToAllOnChange(this.pageContent, '.lease-extendable-no',
            this.handleLeaseExtendableClick)
        this.updatePage()
    }
    updatePage(ignored) {
        const divs = this.pageContent.querySelectorAll('div.lease-detail')
        let i;
        for (i = 0; i < divs.length; i++) {
            const div = divs[i];
            this.handleLeaseExtendableClick({'target': div.querySelector('input.lease-extendable-yes')})
        }
    }
    handleLeaseExtendableClick(e) {
        const leaseDetailDiv = e.target.closest('.lease-detail')
        const display = leaseDetailDiv.querySelector('input.lease-extendable-yes').checked
        FormHandlerHelpers.toggleDivClearValue(e.target, leaseDetailDiv, '.lease-extendable-length', display)
    }
}