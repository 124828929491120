import FileUploadButtonHandler from 'pages/ConnectedMicrogrids/shared/FileUploadButtonHandler';
import AllowNaButtonHandler from 'pages/ConnectedMicrogrids/shared/AllowNaButtonHandler';
import AddDocumentButtonHandler from './AddDocumentButtonHandler';
import ConnectedMicrogridDisabledCheckboxHandler from './ConnectedMicrogridDisabledCheckboxHandler';
import ConnectedMicrogridDocumentFormHandler from 'pages/ConnectedMicrogrids/shared/ConnectedMicrogridDocumentFormHandler';
import ConnectedMicrogridDocumentSubmitCallback from './ConnectedMicrogridDocumentSubmitCallback';

export default class Home {
  static initialize() {
    const pageContent = document.querySelector('#connected-microgrids-home-index');

    if (!pageContent) return;

    new FileUploadButtonHandler(pageContent);
    new AllowNaButtonHandler(pageContent);
    new AddDocumentButtonHandler(pageContent);
    new ConnectedMicrogridDisabledCheckboxHandler(pageContent);
    new ConnectedMicrogridDocumentFormHandler(
      pageContent,
      ConnectedMicrogridDocumentSubmitCallback
    );
  }
}
