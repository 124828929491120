import OrderInformations from 'pages/OrderInformations';
import PowerPlans from 'pages/Retails/PowerPlans';
import ConnectedMicrogridsHome from 'pages/ConnectedMicrogrids/Home';
import ConnectedMicrogridsCompanyInformation from 'pages/ConnectedMicrogrids/CompanyInformation';

export default class Pages {
  static initialize() {
    OrderInformations.initialize();
    PowerPlans.initialize();
    ConnectedMicrogridsHome.initialize();
    ConnectedMicrogridsCompanyInformation.initialize();
  }
}
