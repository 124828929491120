import CalculatePercentageConnectedMicrogridDocuments from 'pages/ConnectedMicrogrids/shared/CalculatePercentageConnectedMicrogridDocuments';
import { refreshPage } from 'utils/routing';

export default class ConnectedMicrogridDocumentSubmitCallback {
  constructor(pageContent, form) {
    this.pageContent = pageContent;
    this.form = form;

    if (!this.hasConnectedMicrogridDocumentType(form)) return;
    const connectedMicrogridDocuments = this.findConnectedMicrogridDocuments(form);

    const percents = new CalculatePercentageConnectedMicrogridDocuments(connectedMicrogridDocuments).result();
    const progressBar = this.findProgressBar(form);
    const percentsString = `${percents}%`
    progressBar.style.width = percentsString;
    progressBar.firstElementChild.innerText = percentsString;
    if (percents == 100) refreshPage();
  }

  findConnectedMicrogridDocuments(form) {
    const connectedMicrogridDocuments = form.closest('.connectedMicrogridDocuments').querySelectorAll('.connectedMicrogridDocumentForm');

    return [...connectedMicrogridDocuments].filter(connectedMicrogridDocument => {
      return this.hasConnectedMicrogridDocumentType(connectedMicrogridDocument);
    })
  }

  findProgressBar(form) {
    const companyLocationId = form.querySelector('#connected_microgrid_document_company_location_id').value;
    return this.pageContent.querySelector(`[data-target=".company-location-tab-${companyLocationId}"] .progress-indicator`);
  }

  hasConnectedMicrogridDocumentType(form) {
    const documentType = form.querySelector('#connected_microgrid_document_connected_microgrid_document_type_id');
    return !!documentType.value;
  }
}
