import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "entity_name_div", "entity_name", "residential"
    ]

    connect() {
        this._toggle(this.residentialTarget.checked)
    }

    type_change(event) {
        this._toggle(event.currentTarget.value == 'residential')
    }

    _toggle(isResi) {
        const entityDiv = this.entity_name_divTarget;
        if (isResi) {
            entityDiv.style.display = 'none'
            this.entity_nameTarget.value = ''
        } else {
            entityDiv.style.display = 'block'
        }

    }
}
