import { Controller } from "stimulus"
import removeSelectOptions from 'utils/select_utilities'

export default class extends Controller {
    static targets = ["utility_contract_container", "utility_selector", "state", "id_input", "id_label"]

    populateSelectWithStateUtilities(state, select) {
        const utilities = JSON.parse(this.utility_contract_containerTarget.dataset.utilities)
        utilities.filter((u) => u.state === state).forEach((utility => {
            const { id, utility: utilityName, utility_short_name } = utility;
            const option = document.createElement('option');
            option.value = id;
            option.text = utilityName;
            option.dataset.utilityShortName = utility_short_name
            select.add(option)
        }))
    }

    state_change(event) {
        if (event) {
            event.preventDefault()
        }
        const state = this.stateTarget.selectedOptions[0].text
        this.utility_selectorTargets.forEach((select) => {
            if (event?.detail?.type === 'new_utility_contract'){
                if(select.selectedIndex === 0) {
                    removeSelectOptions(select, 1)
                    this.populateSelectWithStateUtilities(state, select)
                }
            } else {
                removeSelectOptions(select, 1)
                this.populateSelectWithStateUtilities(state, select)
            }
        })
        if (event) {
            event.stopPropagation()
        }
    }
}
