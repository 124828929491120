import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "add_location", "location_template",
    ]

    _populate_template(template) {
        const randomLargeNumber = Math.floor(Math.random() * 100)
        return template.replace(/TEMPLATE_RECORD/g, randomLargeNumber)
    }

    add_location(event) {
        event.preventDefault()
        const replaced = this._populate_template(this.location_templateTarget.innerHTML)
        this.add_locationTarget.insertAdjacentHTML('beforebegin', replaced)
    }

    remove_location(event) {
        event.preventDefault()
        let item = event.target.closest(".companyLocationParent")
        item.remove()
    }
}
