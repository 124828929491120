export default class FormHandlerHelpers{
    static bindToAllOnChange(node, cssClass, func) {
        const nodes = node.querySelectorAll(cssClass);
        let i;
        for (i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            node.addEventListener('change', func.bind(this));
        }
    }
    static toggleDivClearValue(target, div, inputClassName, display) {
        const classDiv = div.querySelector(inputClassName)
        if (display) {
            classDiv.style.display = "block";
        } else {
            const input = classDiv.querySelector(inputClassName)
            input.value = ''
            classDiv.style.display = "none";
        }
    }
}
