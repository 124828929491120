import Inputmask from "inputmask";

export default class PhoneInput {
  static initialize() {
    new this();
  }

  constructor() {
    this.phoneInputs = document.querySelectorAll('input[type=tel]');

    this.addMaskToPhoneInputs();
  }

  addMaskToPhoneInputs() {
    Inputmask({ mask: '(999) 999 9999' }).mask(this.phoneInputs);
  }
}
