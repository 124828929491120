import { disableInputs } from 'utils/form'

export default class CompanyLocationFormInitializer {
  constructor(companyLocationForm, newDatasetIndex) {
    this.companyLocationForm = companyLocationForm.cloneNode(true);
    this.formDatasetIndex = this.companyLocationForm.dataset.index;
    this.newDatasetIndex = newDatasetIndex;
  }

  render() {
    this.setDataIndexToForm();
    this.resetAddressForm();
    this.resetOccupancyTypeForm();
    this.resetCommodityForm();
    this.resetOnSiteProductsForm();
    this.uncheckLOAChackbox();
    return this.companyLocationForm;
  }

  setDataIndexToForm() {
    this.companyLocationForm.dataset.index = this.newDatasetIndex;
  }

  resetAddressForm() {
    this.resetInputs(this.companyLocationForm.querySelector('.companyLocationAddress'));
  }

  resetOccupancyTypeForm() {
    const ownPane = this.companyLocationForm.querySelector('.tooltip-own-pane')
    const leasePane = this.companyLocationForm.querySelector('.tooltip-lease-pane')
    this.companyLocationForm.querySelector('.leaseRadioButton').checked = true;
    this.companyLocationForm.querySelector('.ownLabel').classList.remove('active');
    leasePane.classList.add('show');
    ownPane.classList.remove('show');
    this.resetInputs(this.companyLocationForm.querySelector('.occupancyTypeForm'));
    disableInputs(ownPane, true);
    disableInputs(leasePane, false);
  }

  resetCommodityForm() {
    this.resetCommodityButtonsState();
    this.resetTabPanes();
  }

  resetOnSiteProductsForm() {
    this.resetInputs(this.companyLocationForm.querySelector('.onSiteProducts'));
  }

  uncheckLOAChackbox() {
    this.companyLocationForm.querySelector('.acceptLOACheckbox').checked = false;
  }

  resetCommodityButtonsState() {
    [...this.companyLocationForm.querySelectorAll('.commodityButtons .toggle-button')].forEach(toggleButton => {
      if (toggleButton.classList.contains('both')) {
        toggleButton.classList.add('active');
      } else {
        toggleButton.classList.remove('active');
      }
    })
  }

  resetTabPanes() {
    [...this.companyLocationForm.querySelectorAll('.utility-contract-form')].forEach(utilityContractForm => {
      this.removeRredundantUtilityContractInputRows(utilityContractForm);
      this.removeIdAndDeletedInputs(utilityContractForm);
      this.resetInputs(utilityContractForm);
      this.resetSuplierTabs(utilityContractForm);
      this.showUtilityContractForm(utilityContractForm);
    })
  }

  removeRredundantUtilityContractInputRows(utilityContractForm) {
    const utilityContractRows = [...utilityContractForm.querySelectorAll('.utilityContractInputs')]
    utilityContractRows.shift();
    utilityContractRows.forEach(row => row.remove())
  }

  resetSuplierTabs(utilityContractForm) {
    utilityContractForm.querySelector('.suplierButton').classList.remove('active');
    utilityContractForm.querySelector('.utilityButton').classList.add('active');
    const suplierPane = utilityContractForm.querySelector('.suplier-pane')
    suplierPane.classList.remove('show')
    disableInputs(suplierPane, true);
  }

  showUtilityContractForm(utilityContractForm) {
    utilityContractForm.classList.remove('d-none');
    utilityContractForm.classList.add('d-block');
    disableInputs(utilityContractForm.querySelector('.utilityContractFormContainer'), false);
  }

  removeIdAndDeletedInputs(form) {
    [...form.querySelectorAll('.idInput, .markAsDeleted')].forEach(input => {
      input.remove();
    })
  }

  resetInputs(form) {
    Array.from(form.querySelectorAll('input, select')).forEach(input => {
      const id = input.getAttribute('id');
      const name = input.getAttribute('name');

      input.setAttribute('name', this.nameAttributeForNewIndex(name));
      this.resetInputValue(input);

      if(!!id) {
        input.setAttribute('id', this.idAttributeForNewIndex(id));
      }
    })

    Array.from(form.querySelectorAll('label')).forEach(label => {
      const id = label.getAttribute('for');

      if (!id) return;

      label.setAttribute('for', this.idAttributeForNewIndex(id));
    })
  }

  nameAttributeForNewIndex(name) {
    const oldName = this.generateInputName(this.formDatasetIndex);
    const newName = this.generateInputName(this.newDatasetIndex);
    return name.replace(oldName, newName);
  }

  idAttributeForNewIndex(id) {
    const oldId = this.generateInputId(this.formDatasetIndex);
    const newId = this.generateInputId(this.newDatasetIndex);

    return id.replace(oldId, newId);
  }

  resetInputValue(input) {
    if (input.classList.contains('notResetable')) return;

    if (input.type !== 'radio' && input.type  !== 'checkbox') {
      input.value = '';
    }

    if (input.type  === 'checkbox') {
      input.checked = false;
    }
  }

  generateInputName(index) {
    return `company[company_locations_attributes][${index}]`;
  }

  generateInputId(index) {
    return `company_company_locations_attributes_${index}_`;
  }
}
