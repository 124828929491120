export default class LOAModalHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.addEventListener('click', this.handleTabButtonClick.bind(this));
  }

  handleTabButtonClick(e) {
    const button = e.target;
    if (!button.classList.contains('showLOAModal')) return;
    const modal = this.modalForButton(button);

    this.fillTextUtilities(modal);
    this.fillMailingAddress(modal);
    this.fillAccountsCount(modal);
    this.fillAccounts(modal);

    $(modal).modal('show')
  }

  container(document) {
    return document.closest('.companyLocationFieldset');
  }

  modalForButton(button) {
    return this.container(button).querySelector('.loaModal')
  }


  fillTextUtilities(modal) {
    const utilities = [...this.container(modal).querySelectorAll('.utilitySelect')].map(
      (select) => select.options[select.selectedIndex].text
    ).filter(i => !!i).join(', ');

    modal.querySelector('.text-utility').innerText = utilities;
  }

  fillMailingAddress(modal) {
    const mailingAddress = this.findMailingAddress()

    modal.querySelector('.mailing-address').innerText = this.formatAddress(mailingAddress);
  }

  fillAccountsCount(modal) {
    modal.querySelector('.account-number').innerText = this.container(modal).querySelectorAll('.utilitySelect:not(:disabled)').length;
  }

  fillAccounts(modal) {
    const accountRow = modal.querySelector('.accountRow').cloneNode(true);
    const container = this.container(modal);
    [...modal.querySelectorAll('.accountRow')].forEach((account) => account.remove());
    const address = this.formatAddress(container);
    const modalBody = modal.querySelector('.modal-body');

    [...container.querySelectorAll('.utilityNumber:not(:disabled)')].forEach((numberInput) => {
      const row = accountRow.cloneNode(true);
      row.querySelector('.utility-address').innerText = address;
      row.querySelector('.utility-number').innerText = numberInput.value;
      modalBody.appendChild(row);
    })
  }

  findMailingAddress() {
    const useAsBillingAddress = [...this.pageContent.querySelectorAll('.companyLocationFieldset')].find((companyLocationFieldset) => {
      return companyLocationFieldset.querySelector('.useAsBillingAddress').checked;
    });

    return useAsBillingAddress || this.pageContent.querySelector('.billingAddressForm');
  }

  formatAddress(companyLocationFieldset) {
    const address_1 = companyLocationFieldset.querySelector('.street').value;
    const address_2 = companyLocationFieldset.querySelector('.address-2').value;
    const city = companyLocationFieldset.querySelector('.city').value;
    const stateInput = companyLocationFieldset.querySelector('.state');
    const state = stateInput.options[stateInput.selectedIndex].text;
    const zip = companyLocationFieldset.querySelector('.zip').value;

    return [address_1, address_2, city, state, zip].filter((i) => i).join(', ');
  }
}
