import CompanyLocationFormInitializer from './CompanyLocationFormInitializer';
import AddressAutocomplete from 'components/AddressAutocomplete';
import AddUtilityContractFormHandler from './AddUtilityContractFormHandler';
import LOACheckboxHandler from './LOACheckboxHandler';
import SelectUtilitiesByStateHandler from './SelectUtilitiesByStateHandler';
import SupplierFormHandler from "./SupplierFormHandler";
import OccupancyTypeFormHandler from "./OccupancyTypeFormHandler";
import UtilitiesSelectChangeHandler from "./UtilitiesSelectChangeHandler";

export default class CompanyLocationFormHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.supplierFormHandler = new SupplierFormHandler(pageContent);
    this.occupancyTypeFormHandler = new OccupancyTypeFormHandler(pageContent);
    this.companyLocationForm = this.getCompanyLocationForm();

    this.addDataIndexesToFieldsets();
    this.addNewCompanyLocationButton().addEventListener('click', this.handleAddNewCompanyLocationButtonClick.bind(this));
    this.allRemoveCompanyLocationButtons().forEach((button) => {
      button.addEventListener('click', this.handleRemoveCompanyLocationButtonClick.bind(this));
    })
  }

  handleAddNewCompanyLocationButtonClick(e) {
    const companyLocationsFieldsets = this.companyLocationsFieldsets();
    const lastFieldsetIndex = companyLocationsFieldsets.length - 1
    const companyLocationFieldset = companyLocationsFieldsets[0].cloneNode(true);
    const newDatasetIndex = Number(companyLocationsFieldsets[lastFieldsetIndex].dataset.index) + 1;

    const form = new CompanyLocationFormInitializer(companyLocationFieldset, newDatasetIndex).render();
    this.companyLocationForm.appendChild(form);
    this.initializeAdditionalHandlers(form);
    this.supplierFormHandler.render()
    this.occupancyTypeFormHandler.render()
    this.handleAllAccountsSameLegalEntitySectionVisibility();
  }

  handleRemoveCompanyLocationButtonClick(e) {
    e.target.closest('.companyLocationFieldset').remove();
    this.handleAllAccountsSameLegalEntitySectionVisibility();
  }

  initializeAdditionalHandlers(pageContent) {
    this.removeCompanyLocationLink(pageContent) && this.removeCompanyLocationLink(pageContent).remove();
    this.removeCompanyLocationButton(pageContent).classList.remove('d-none');
    this.removeCompanyLocationButton(pageContent)
      .addEventListener('click', this.handleRemoveCompanyLocationButtonClick.bind(this));
    AddressAutocomplete.initialize(pageContent.querySelector('.addressAutocomplete'));
    new AddUtilityContractFormHandler(pageContent);
    new LOACheckboxHandler(pageContent);
    new SelectUtilitiesByStateHandler(pageContent);
    new UtilitiesSelectChangeHandler(pageContent);
  }

  addDataIndexesToFieldsets() {
    Array.from(this.companyLocationsFieldsets()).forEach((fieldset, index) => {
      fieldset.dataset.index = index
    })
  }

  handleAllAccountsSameLegalEntitySectionVisibility() {
    if (this.companyLocationsFieldsets().length == 1) {
      this.allAccountsSameLegalEntitySection().classList.add('d-none');
      return;
    }
    this.allAccountsSameLegalEntitySection().classList.remove('d-none');
  }

  addNewCompanyLocationButton() {
    return this.pageContent.querySelector('.addNewCompanyLocationButton');
  }

  removeCompanyLocationButton(content) {
    return content.querySelector('.removeCompanyLocationButton');
  }

  removeCompanyLocationLink(content) {
    return content.querySelector('.removeCompanyLocationLink');
  }

  allRemoveCompanyLocationButtons() {
    return Array.from(this.pageContent.querySelectorAll('.removeCompanyLocationButton'));
  }

  getCompanyLocationForm() {
    return this.pageContent.querySelector('#companyLocationForm');
  }

  companyLocationsFieldsets() {
    return this.companyLocationForm.querySelectorAll('.companyLocationFieldset');
  }

  allAccountsSameLegalEntitySection() {
    return this.pageContent.querySelector('.allAccountsSameLegalEntitySection');
  }
}
