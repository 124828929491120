import { disableInputs } from 'utils/form'

export default class BillingAddressCheckboxHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.billingAddress = this.pageContent.querySelector('.billingAddressForm')

    this.pageContent.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(e) {
    const target = e.target;

    if (!target.classList.contains('useAsBillingAddress')) return;

    const checkboxes = this.useAsBillingAddressCheckBoxes();
    this.uncheckAnotherCheckboxes(checkboxes, target);
    this.handleBillingAddressFormVisibility(checkboxes);
  }

  uncheckAnotherCheckboxes(checkboxes, target) {
    if (!target.checked) return;

    checkboxes.forEach((checkbox) => {
      if (checkbox !== target) {
        checkbox.checked = false;
      }
    });
  }

  handleBillingAddressFormVisibility(checkboxes) {
    if (checkboxes.some((checkbox) => checkbox.checked)) {
      this.billingAddress.classList.add('d-none');
      disableInputs(this.billingAddress, true);
    } else {
      this.billingAddress.classList.remove('d-none');
      disableInputs(this.billingAddress, false);
    }
  }

  useAsBillingAddressCheckBoxes() {
    return [...this.pageContent.querySelectorAll('.useAsBillingAddress')];
  }
}
