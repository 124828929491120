import Inputmask from "inputmask";

export default class UtilitiesSelectChangeHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    const utilityContractFormContainer = document.body.querySelector('.utility-contract-form .utilityContractFormContainer')
    this.labelsMapping = JSON.parse(
      utilityContractFormContainer.dataset.labelsMapping
    );
    this.maskMapping = JSON.parse(
      utilityContractFormContainer.dataset.utilityMaskMapping
    );

    [...this.utilitySelects()].forEach((utilitySelect) => {
      utilitySelect.addEventListener('change', this.handleUtilityChange.bind(this))
    })
  }

  handleUtilityChange(e) {
    const { target } = e;
    const selectedOption = target.selectedOptions[0];
    const accountNumberInput = target.closest('.utilityContractInputs').querySelector('.utilityNumber');
    const utilityShortName = selectedOption.dataset.utilityShortName

    const placeholder = this.labelsMapping[utilityShortName] || this.labelsMapping.default
    accountNumberInput.placeholder = placeholder;

    // see: https://github.com/RobinHerbots/Inputmask#readme
    if (utilityShortName in this.maskMapping) {
      Inputmask({regex: this.maskMapping[utilityShortName].regex}).mask(accountNumberInput);
    } else {
      // if no mask exists in the DB, default to:
      //   mask: "*" -- alphanumeric
      //   repeat: "*" + greedy: false  -- any length, lazy / shortest match
      Inputmask({mask: "*", repeat: "*", greedy: false}).mask(accountNumberInput);
    }

    accountNumberInput.disabled = false;
  }

  utilitySelects() {
    return this.pageContent.querySelectorAll('.utilitySelect');
  }
}
