import UtilitiesSelectChangeHandler from "./UtilitiesSelectChangeHandler";

export default class AddUtilityContractFormHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.companyLocationFieldset')].forEach((companyLocationFieldset) => {
      this.addDataIndexesToForms(companyLocationFieldset)
    });
    [...this.pageContent.querySelectorAll('.addUtilityContractFormButton')].forEach((button) => {
      button.onclick = this.handleAddNewUtilityContractFormButtonClick.bind(this);
    });
    [...this.pageContent.querySelectorAll('.utilityNumber')].forEach((input) => {
      input.disabled = true
    })
  }

  handleAddNewUtilityContractFormButtonClick(e) {
    const target = e.target;
    const companyLocationIndex = target.closest('.companyLocationFieldset').dataset.index
    const utilityContractInputSets = this.utilityContractsForms(target.closest('.utility-contract-form'));
    const utilityContractInputSet = utilityContractInputSets[0].cloneNode(true);
    const lastIndex = this.lastDataIndex(target);
    const newIndex = lastIndex + 1;

    this.updateInputs(utilityContractInputSet, companyLocationIndex, lastIndex, newIndex);
    target.closest('.utility-contract-form').querySelector('.utilityContractFormContainer').appendChild(utilityContractInputSet);
    new UtilitiesSelectChangeHandler(utilityContractInputSet);
  }

  utilityContractsForms(container) {
    return container.querySelectorAll('.utilityContractInputs')
  }

  addDataIndexesToForms(container) {
    Array.from(this.utilityContractsForms(container)).forEach((fieldset, index) => {
      fieldset.dataset.index = index
    })
  }

  lastDataIndex(button) {
    const forms = this.utilityContractsForms(button.closest('.companyLocationFieldset'));
    return Math.max.apply(Math, [...forms].map((item) => Number(item.dataset.index)))
  }

  updateInputs(form, companyLocationIndex, lastIndex, newIndex) {
    const formIndex = form.dataset.index
    form.dataset.index = newIndex;
    Array.from(form.querySelectorAll('input, select')).forEach(input => {
      const id = input.getAttribute('id');
      const name = input.getAttribute('name');

      input.setAttribute('name', this.nameAttributeForNewIndex(name, companyLocationIndex, formIndex, newIndex));
      this.resetInputValue(input);

      if(!!id) {
        input.setAttribute('id', this.idAttributeForNewIndex(id, companyLocationIndex, formIndex, newIndex));
      }
    })

    Array.from(form.querySelectorAll('label')).forEach(label => {
      const id = label.getAttribute('for');

      if (!id) return;

      label.setAttribute('for', this.idAttributeForNewIndex(id, companyLocationIndex, lastIndex, newIndex));
    })
  }

  nameAttributeForNewIndex(name, companyLocationIndex, lastIndex, newIndex) {
    const oldName = this.generateInputName(companyLocationIndex, lastIndex);
    const newName = this.generateInputName(companyLocationIndex, newIndex);
    return name.replace(oldName, newName);
  }

  idAttributeForNewIndex(id, companyLocationIndex, lastIndex, newIndex) {
    const oldId = this.generateInputId(companyLocationIndex, lastIndex);
    const newId = this.generateInputId(companyLocationIndex, newIndex);

    return id.replace(oldId, newId);
  }

  resetInputValue(input) {
    if (input.classList.contains('notResetable')) return;

    if (input.classList.contains('acceptLOAInput')) return;

    if (input.type !== 'radio' && input.type  !== 'checkbox') {
      input.value = ''
    }

    if (input.classList.contains("utilityNumber")) {
      input.disabled = true;
      input.placeholder = "Account Number"
    }
  }

  generateInputName(companyLocationIndex, index) {
    return `company[company_locations_attributes][${companyLocationIndex}][utility_contracts_attributes][${index}]`;
  }

  generateInputId(companyLocationIndex, index) {
    return `company_company_locations_attributes_${companyLocationIndex}_utility_contracts_attributes_${index}_`;
  }
}
