import BillingAddressCheckboxHandler from './BillingAddressCheckboxHandler';
import TabPanesFormHandler from './TabPanesFormHandler';
import CommodityButtonsHandler from './CommodityButtonsHandler';
import AddUtilityContractFormHandler from './AddUtilityContractFormHandler';
import CompanyLocationFormHandler from './CompanyLocationFormHandler';
import LOACheckboxHandler from './LOACheckboxHandler';
import LOAModalHandler from './LOAModalHandler';
import SelectUtilitiesByStateHandler from './SelectUtilitiesByStateHandler';
import RepContractDeleteHandler from './RepContractDeleteHandler';
import UtilityContractDeleteHandler from './UtilityContractDeleteHandler';
import ValuePropsSortingHandler from './ValuePropsSortingHandler';
import SubmitFormHandler from './SubmitFormHandler';
import UtilitiesSelectChangeHandler from "./UtilitiesSelectChangeHandler";

export default class LocationInformations {
  static initialize() {
    const pageContent = document.querySelector('#order_informations');

    if (!pageContent) return;

    new BillingAddressCheckboxHandler(pageContent);
    new TabPanesFormHandler(pageContent);
    new CommodityButtonsHandler(pageContent);
    new AddUtilityContractFormHandler(pageContent);
    new CompanyLocationFormHandler(pageContent);
    new LOACheckboxHandler(pageContent);
    new LOAModalHandler(pageContent);
    new SelectUtilitiesByStateHandler(pageContent);
    new RepContractDeleteHandler(pageContent);
    new UtilityContractDeleteHandler(pageContent);
    new ValuePropsSortingHandler(pageContent);
    new SubmitFormHandler(pageContent);
    new UtilitiesSelectChangeHandler(pageContent);
    LOACheckboxHandler.renitializeValues(pageContent);
  }
}
