export default class NaicsSelect {
  static initialize() {
    new this();
  }

  constructor() {
    this.naicsSelectGroup = document.querySelector('.naicsSelectGroup');

    if (!this.naicsSelectGroup) return;

    this.naicsParentTitle = this.naicsSelectGroup.querySelector('.naicsParentTitle');
    this.naicsClassification = this.naicsSelectGroup.querySelector('.naicsClassification');
    this.naicsClassificationList = JSON.parse(this.naicsClassification.dataset.naicsClassificationList);
    this.selectedClassification = JSON.parse(this.naicsClassification.dataset.selectedClassification);

    this.initialize();
    this.initializeSelect2(this.naicsParentTitle)
    this.initializeSelect2(this.naicsClassification)
    $(this.naicsParentTitle).on('change', this.handleNaicsParentTitlesChange.bind(this))
  }

  initialize() {
    if (!this.selectedClassification) return;

    const { id, parent_key_code } = this.selectedClassification;

    this.handleNaicsClassificationOptions(parent_key_code)
    this.naicsParentTitle.value = parent_key_code;
    this.naicsClassification.value = id;
  }

  initializeSelect2(select) {
    $(select).select2();
  }

  handleNaicsParentTitlesChange(e) {
    const selectedOption = e.target.selectedOptions[0].value
    this.handleNaicsClassificationOptions(selectedOption)
  }

  handleNaicsClassificationOptions(selectedOption) {
    this.naicsClassification.options.length = 1

    if (selectedOption) {
      this.naicsClassification.closest('.form-group').classList.remove('d-none')
      this.naicsClassificationList.filter((item) => item.parent_key_code === Number(selectedOption)).forEach((item) => {
        const option = document.createElement('option');
        option.value = item.id;
        option.text = item.title;
        this.naicsClassification.appendChild(option);
      })
    } else {
      this.naicsClassification.closest('.form-group').classList.add('d-none')
    }
  }
}
