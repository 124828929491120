import { Controller } from "stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
    static targets = ["id_input", "id_label", "utility", "mask_mappings"]

    utility_change(event) {
        const utilMappings = JSON.parse(this.mask_mappingsTarget.innerHTML)
        const idInput = this.id_inputTarget
        const option = this.utilityTarget.options[this.utilityTarget.selectedIndex]
        if (this.utilityTarget.selectedIndex !== 0) {
            const utilityCode = option.getAttribute("data-utility-short-name")
            const utilityInfo = utilMappings[utilityCode]
            if (utilityInfo) {
                Inputmask({regex: utilityInfo.regex}).mask(idInput);
                this.id_labelTarget.innerHTML = utilityInfo.label
            } else {
                Inputmask({regex: '.*'}).mask(idInput);
                this.id_labelTarget.innerHTML = 'Account Number'
            }
        }
    }
}