import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "add_utility_contract", "utility_contract_template", "utility_contract_container",
        "utility_contract_div"
    ]

    _populate_template(template) {
        const randomLargeNumber = Math.floor(Math.random() * 100)
        return template.replace(/TEMPLATE_RECORD/g, randomLargeNumber)
    }

    add_utility_contract(event) {
        event.preventDefault()
        const replaced = this._populate_template(this.utility_contract_templateTarget.innerHTML)
        const element = document.createElement('div');
        element.innerHTML = replaced
        this.add_utility_contractTarget.append(element)
        this.element.dispatchEvent(new CustomEvent('state_change',
            { detail: { type: 'new_utility_contract' }, bubbles: true }))
        // element.dispatchEvent(new CustomEvent('utility_change', {detail: {}}))
        // this.utility_contract_divTargets.forEach((div) => {
        //     div.dispatchEvent(new CustomEvent('utility_change', {detail: {}}))
        // })
    }

    remove_utility_contract(event) {
        const contracts = this.utility_contract_containerTargets
        if (contracts.length == 1) {
            alert('Must have 1 account for a location')
            return
        }
        event.preventDefault()
        let item = event.target.closest(".utilityContractFormContainer")
        item.remove()
    }
}
