import Sortable from "sortablejs";

export default class ValuePropsSortingHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.rankPreferences = this.rankPreferencesDraggable();

    if (!this.rankPreferences) return;

    Sortable.create(this.rankPreferences, {
      handle: '.dragRow',
      onEnd: this.handleDragEnd.bind(this)
    });
  }

  handleDragEnd(e) {
    Array.from(this.draggableItems()).forEach((element, index) => {
      const rankCol = this.rankNumberCol(element);
      this.updateInputsValues(rankCol, index);
      this.updateRowRankNumber(rankCol, index);
    })
  }

  rankPreferencesDraggable() {
    return this.pageContent.querySelector('.rankPreferencesDraggable');
  }

  draggableItems() {
    return this.rankPreferences.children
  }

  rankNumberCol(row) {
    return row.querySelector('.rankCol')
  }

  updateInputsValues(rankCol, index) {
    rankCol.querySelector('.rankInput').value = this.elementIndexToRank(index);
  }

  updateRowRankNumber(rankCol, index) {
    rankCol.querySelector('span').innerText = this.elementIndexToRank(index)
  }

  elementIndexToRank(index) {
    return index + 1;
  }
}
