import { disableInputs } from 'utils/form'
import LOACheckboxHandler from './LOACheckboxHandler';

export default class CommodityButtonsHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(e) {
    const target = e.target;
    const buttonsContainer = this.buttonsContainer(target);
    if (!buttonsContainer) return;
    if (!target.classList.contains('commodityButton')) return;

    const formContainer = this.formContainer(target);

    if (target.classList.contains('elec')) this.handleElecButtonClick(target, buttonsContainer, formContainer);
    if (target.classList.contains('gas')) this.handleGasButtonClick(target, buttonsContainer, formContainer);
    if (target.classList.contains('both')) this.handleBothButtonClick(target, buttonsContainer, formContainer);
    LOACheckboxHandler.renitializeValues(formContainer);
  }

  handleElecButtonClick(button, buttonsContainer, formContainer) {
    this.changeActiveButton(button, buttonsContainer)
    this.hideUtilityContractsForm(formContainer.querySelector('.utility-contract-form.gas'))
    this.showUtilityContractsForm(formContainer.querySelector('.utility-contract-form.elec'))
  }

  handleGasButtonClick(button, buttonsContainer, formContainer) {
    this.changeActiveButton(button, buttonsContainer)
    this.hideUtilityContractsForm(formContainer.querySelector('.utility-contract-form.elec'))
    this.showUtilityContractsForm(formContainer.querySelector('.utility-contract-form.gas'))
  }

  handleBothButtonClick(button, buttonsContainer, formContainer) {
    this.changeActiveButton(button, buttonsContainer)
    this.showUtilityContractsForm(formContainer.querySelector('.utility-contract-form.elec'))
    this.showUtilityContractsForm(formContainer.querySelector('.utility-contract-form.gas'))
  }

  changeActiveButton(button, container) {
    const activeButton = container.querySelector('.commodityButton.active');

    if (activeButton) activeButton.classList.remove('active');

    button.classList.add('active');
  }

  buttonsContainer(button) {
    return button.closest('.commodityButtons');
  }

  formContainer(button) {
    return button.closest('.companyLocationFieldset');
  }

  showUtilityContractsForm(target) {
    this.updateInputs(target, false);
    target.classList.replace('d-none', 'd-block')
    const suplierPane = target.querySelector('.suplier-pane');
    if (suplierPane.classList.contains('show')) {
      disableInputs(suplierPane, false);
    }
  }

  hideUtilityContractsForm(target) {
    this.updateInputs(target, true);
    target.classList.replace('d-block', 'd-none')
    const suplierPane = target.querySelector('.suplier-pane');
    disableInputs(suplierPane, true);
  }

  updateInputs(target, disabled) {
    disableInputs(target.querySelector('.utilityContractFormContainer'), disabled);
    this.updateMarkAsDeletedInputs(target, disabled);
  }

  updateMarkAsDeletedInputs(target, deleted) {
    const inputs = target.querySelectorAll('.markAsDeleted');
    [...inputs].forEach((input) => {
      input.value = deleted;
    });
  }
}
