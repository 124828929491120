export default class LOACheckboxHandler {
  static renitializeValues(pageContent) {
    [...pageContent.querySelectorAll('.acceptLOACheckbox')].forEach((checkbox) =>{
      const checkboxFieldset = checkbox.closest('.companyLocationFieldset');
      const checked = [...checkboxFieldset.querySelectorAll('.acceptLOAInput:not([disabled])')].every(
        input => input.value == 'true'
      );

      checkbox.checked = checked;
    });
  }

  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.acceptLOACheckbox')].forEach((checkbox) =>{
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
    });
  }

  handleCheckboxChange(e) {
    const checked = e.target.checked;

    [...e.target.closest('.companyLocationFieldset').querySelectorAll('.acceptLOAInput')].forEach((input) => {
      input.value = checked;
    });
  }
}
