export default class RepContractDeleteHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(e) {
    const target = e.target;
    if (!target.classList.contains('tabButton')) return;

    if (target.classList.contains('utilityButton')) return this.handleUtilityButtonClick(target);
    if (target.classList.contains('suplierButton')) return this.handleSuplierButtonClick(target);
  }

  handleUtilityButtonClick(button) {
    this.updateMarkAsDeletedInputs(button, true)
  }

  handleSuplierButtonClick(button) {
    this.updateMarkAsDeletedInputs(button, false)
  }

  updateMarkAsDeletedInputs(button, deleted) {
    const inputs = button.closest('.tabs').querySelectorAll('.suplier-pane .markAsDeleted');
    [...inputs].forEach((input) => {
      input.value = deleted;
    });
  }
}
