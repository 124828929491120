export default class ConnectedMicrogridDocumentFormHandler {
  constructor(pageContent, onDocumentSubmitCallback) {
    this.pageContent = pageContent;
    this.onDocumentSubmitCallback = onDocumentSubmitCallback;

    this.pageContent.addEventListener('click', this.submitButtonHandler.bind(this))
  }

  submitButtonHandler(e) {
    if (!e.target.classList.contains('submitDocumentButton')) return
    e.preventDefault();
    const form = e.target.form;

    if (!!this.documentId(form)) {
      this.handleUpdate(form)
    } else {
      this.handleCreate(form)
    }
  }

  handleUpdate(form) {
    this.makeRequest(
      form,
      this.updateDocumentPath(this.documentId(form)),
      'PATCH',
      this.handleResponse
    )
  }

  handleCreate(form) {
    this.makeRequest(
      form,
      this.createDocumentPath(),
      'POST',
      this.handleResponse
    )
  }

  makeRequest(form, url, method, callback) {
    this.setDisabledSubmitButton(form, true)
    const formData = new FormData(form)
    $.ajax({
      type: method,
      url: url,
      processData: false,
      contentType: false,
      data: formData
    }).done((response) => {
      this.resetErrors(form);

      this.setDisabledSubmitButton(form, false)
      const { errors } = response;

      if (errors) return this.showErrors(form, errors);

      callback(form, response);
    })
  }

  documentId(form) {
    return form.querySelector('.idInput').value;
  }

  idInput(form) {
    return form.querySelector('.idInput');
  }

  setDisabledSubmitButton(form, disabled) {
    const submitButton = form.querySelector('[type="submit"]');
    submitButton.disabled = disabled;
    const spinner = form.querySelector('.spinner-border');
    disabled ? spinner.classList.remove('d-none') : spinner.classList.add('d-none');
  }

  handleResponse = (form, response) => {
    this.idInput(form).value = response.id;

    const indicator = form.querySelector('.indicator');
    if (!indicator.classList.contains('completed')) indicator.classList.add('completed');
    form.querySelector('.submitted-icon-container').classList.remove('d-none');
    new this.onDocumentSubmitCallback(this.pageContent, form)
  }

  resetErrors = (form) => {
    [...form.querySelectorAll('.invalid-feedback')].forEach(invalidFeedback => {
      if (!invalidFeedback.classList.contains('d-none')) invalidFeedback.classList.add('d-none');
      invalidFeedback.classList.remove('d-block');
      invalidFeedback.innerText = '';
    })
  }

  showErrors = (form, errors) => {
    Object.keys(errors).forEach(errorKey => {
      const invalidFeedback = form.querySelector(`.invalid-feedback.${errorKey}`);
      invalidFeedback.innerText = errors[errorKey].join(', ');
      invalidFeedback.classList.remove('d-none');
      invalidFeedback.classList.add('d-block');
    });
  }

  updateDocumentPath = (id) => `/connected_microgrids/connected_microgrid_documents/${id}.json`
  createDocumentPath = () => '/connected_microgrids/connected_microgrid_documents.json'
}
