import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "quote_container", "refresh_px_button", "start_date_input", "toggle_quote_button"
    ]

    initialize() {
        this.quote_containerTarget.hidden = false
    }

    toggle_quote_visibility(event) {
        event.preventDefault()
        this.quote_containerTarget.hidden = !this.quote_containerTarget.hidden
        this.toggle_quote_buttonTarget.innerText = this.quote_containerTarget.hidden ? "Expand →" : "← Collapse";
        this._evaluate_submit_button_state()
    }

    start_date_changed(event) {
        this._evaluate_submit_button_state()
    }

    _toggleDisabled(button, enabled) {
        if (enabled) {
            button.disabled = false
            button.classList.remove('disabled')
        } else {
            button.disabled = true
            if (!button.classList.contains('disabled')) {
                button.classList.add('disabled')
            }
        }
    }

    _evaluate_submit_button_state() {
        const startDateValue = this.start_date_inputTarget.value
        if((!startDateValue || startDateValue.length === 0 )) {
            this._toggleDisabled(this.refresh_px_buttonTarget, false)
        } else {
            this._toggleDisabled(this.refresh_px_buttonTarget, true)
        }
    }
}
