export default class AllowNaButtonHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.allowNaCheckbox')].forEach(
      checkbox => checkbox.addEventListener('change', this.handleAllowNaCheckboxChange.bind(this))
    )
  }

  handleAllowNaCheckboxChange(e) {
    const target = e.target;
    const { checked } = target;

    const container = target.closest('.connected-microgrid-document-form');
    const label = target.closest('label');
    const notes = container.querySelector('.notes');
    const fileUploadField = container.querySelector('.fileUploadField');

    if (checked) {
      label.classList.remove('btn-outline-orange');
      label.classList.add('btn-outline-dark-blue');
    } else {
      label.classList.remove('btn-outline-dark-blue');
      label.classList.add('btn-outline-orange');
    }

    if (notes.dataset.requiredNotesIfNa === 'true') notes.required = checked;
  }
}
