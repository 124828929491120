import FileUploadButtonHandler from 'pages/ConnectedMicrogrids/shared/FileUploadButtonHandler';
import AllowNaButtonHandler from 'pages/ConnectedMicrogrids/shared/AllowNaButtonHandler';

export default class AddDocumentButtonHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.addDocumentBtn')].forEach(
      fileUploadField => fileUploadField.addEventListener('click', this.handleBtnClick.bind(this))
    )
  }

  handleBtnClick(e) {
    const target = e.target;

    const connectedMicrogridDocuments = target.closest('.content').querySelector('.connectedMicrogridDocuments');
    const otherDocumentForm = connectedMicrogridDocuments.querySelector('.otherDocumentForm').cloneNode(true);

    otherDocumentForm.classList.remove('d-none');

    new FileUploadButtonHandler(otherDocumentForm);
    new AllowNaButtonHandler(otherDocumentForm);
    new AddDocumentButtonHandler(otherDocumentForm);

    connectedMicrogridDocuments.appendChild(otherDocumentForm);
  }
}
