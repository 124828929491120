export default class SelectUtilitiesByStateHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.gasUtilities = this.pageContent.querySelector('.utility-contract-form.gas .utilityContractFormContainer').dataset.utilities;
    this.elecUtilities = this.pageContent.querySelector('.utility-contract-form.elec .utilityContractFormContainer').dataset.utilities;

    [...this.stateSelects()].forEach((stateSelect) => {
      stateSelect.addEventListener('change', this.handleStateChange.bind(this))
    })
  }

  handleStateChange(e) {
    const state = e.target.selectedOptions[0].text;

    [...this.utilitySelectsFor(e.target)].forEach((select) => {
      this.createOptions(select, state);
    })
  }

  createOptions(select, state) {
    select.options.length = 1
    const isElec = select.closest('.utility-contract-form').classList.contains('elec')
    const utilities = isElec ? this.elecUtilities : this.gasUtilities;

    JSON.parse(utilities).filter((u) => u.state === state).forEach((utility) => {
      const { id, utility: utilityName, utility_short_name } = utility;
      const option = document.createElement('option');
      option.value = id;
      option.text = utilityName;
      option.dataset.utilityShortName = utility_short_name
      select.appendChild(option);
    })
  }

  stateSelects() {
    return this.pageContent.querySelectorAll('.companyLocationState')
  }

  utilitySelectsFor(state) {
    return state.closest('.companyLocationFieldset').querySelectorAll('.utilitySelect')
  }
}
