import { disableInputs } from 'utils/form'

export default class TabPanesFormHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.addEventListener('click', this.handleTabButtonClick.bind(this));
  }

  handleTabButtonClick(e) {
    const button = e.target;
    if (!button.classList.contains('tabButton')) return;

    const container = this.container(button);
    this.handleTabPaneChange(container, button);
  }

  container(tabButton) {
    return tabButton.closest('.tabs');
  }

  handleTabPaneChange(container, button) {
    const target = button.dataset.target;
    const activeButton = container.querySelector('.tabButton.active');
    const activePane = container.querySelector('.tabPane.show');

    disableInputs(container, true);

    if (target) disableInputs(container.querySelector(target), false);
  }
}
