export default class CalculatePercentageConnectedMicrogridDocument {
  constructor(connectedMicrogridDocuments) {
    this.connectedMicrogridDocuments = connectedMicrogridDocuments;
  }

  result() {
    const percents = (this.submittedConnectedMicrogridDocuments().length / this.connectedMicrogridDocuments.length) * 100;
    const rounded = Math.round(percents);

    return rounded > 100 ? 100 : rounded;
  }

  submittedConnectedMicrogridDocuments() {
    return [...this.connectedMicrogridDocuments].filter(connectedMicrogridDocument => {
      return !!connectedMicrogridDocument.querySelector('.idInput').value
    })
  }
}
