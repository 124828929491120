
export default class TabsHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.currentTabInput = this.pageContent.querySelector('input#current_tab');

    this.pageContent.addEventListener('click', this.handleTabButtonClick.bind(this));
  }

  handleTabButtonClick(e) {
    const button = e.target;
    const tabButton = button.classList.contains('tabButton') ? button : button.closest('.tabButton')
    if (!tabButton) return;

    this.currentTabInput.value = tabButton.dataset.target.slice(1);
  }
}
