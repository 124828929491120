import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "customer_name_key", "billing_address_checkbox", "submit"
    ]

    connect() {
        this.billing_address_checkboxTargets.forEach((input) => {
            if (input.checked) {
                this._toggleDisabledOnSubmit(input.checked);
            }
        })
    }

    populate_customer_name_key(event) {
        const nameKey = this.customer_name_keyTarget
        if (event.target.value !== null) {
            nameKey.value = event.target.value.substring(0, 4).toUpperCase()
        }
    }

    upper_customer_name_key(event) {
        const nameKey = this.customer_name_keyTarget
        nameKey.value = nameKey.value.toUpperCase()
    }

    billing_address_changed(event) {
        this._uncheckOtherBillingAddress(event);
        this._toggleDisabledOnSubmit(event.target.checked);
    }


    _uncheckOtherBillingAddress(event) {
        this.billing_address_checkboxTargets.forEach((input) => {
            if (input.id !== event.target.id) {
                input.checked = false
            }
        })
    }

    _toggleDisabledOnSubmit(submitChecked) {
        const submitButton = this.submitTarget
        if (submitChecked) {
            submitButton.disabled = false
            submitButton.classList.remove('disabled')
        } else {
            submitButton.disabled = true
            if (!submitButton.classList.contains('disabled')) {
                submitButton.classList.add('disabled')
            }
        }
    }
}
