import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

export default class DatepickerHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.startDateInput = pageContent.querySelector('.startDate')

    flatpickr(this.startDateInput, {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F, Y",
      minDate: this.startDateInput.dataset.minDate,
      maxDate: this.startDateInput.dataset.maxDate,
      plugins: [
        new monthSelectPlugin({
          dateFormat: "Y-m-d",
          altFormat: "F Y",
        })
      ]
    });
  }
}
