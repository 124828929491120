import { refreshPage } from 'utils/routing';

export default class ProgressBarHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;
    this.progressIndicator = this.pageContent.querySelector('.progress-indicator')

    if (!this.progressIndicator) return;

    if (window.appEnvironment !== undefined && window.appEnvironment.progressTimeout !== undefined) {
      this.secondsToTimeout = window.appEnvironment.progressTimeout
    } else {
      this.secondsToTimeout = 60
    }

    this.initializeProgress()
    this.refreshPage()
  }

  initializeProgress() {
    let i = 1;

    for(i; i <= 100; i++) {
      setTimeout(() => {
        this.increaseProgress();
      }, i * 10 * this.secondsToTimeout)
    }
  }

  increaseProgress() {
    const lastWidth = this.progressIndicator.style.width
    const newWidth = `${++lastWidth.split('%')[0]}%`
    this.progressIndicator.style.width = newWidth;
    this.progressIndicator.firstElementChild.innerText = newWidth;
  }

  refreshPage() {
    setTimeout(() => {
      refreshPage({ progress_timeout: true });
    }, 1000 * this.secondsToTimeout);
  }
}
