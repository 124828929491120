import { disableInputs } from 'utils/form'

export default class UtilityContractDeleteHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(e) {
    const target = e.target;
    if (!target.classList.contains('deleteUtilityContractIcon')) return;

    const container = this.formContainerFor(target);
    const idInput = this.idInput(container);
    const deleteInput = this.deletedInput(container);

    if (idInput && !!idInput.value) {
      deleteInput.value = true;
      disableInputs(container, true);
      container.classList.replace('d-flex', 'd-none')
    } else {
      container.remove()
    }
  }

  formContainerFor(button) {
    return button.closest('.utilityContractInputs');
  }

  deletedInput(container) {
    return container.querySelector('.markAsDeleted');
  }

  idInput(container) {
    return container.querySelector('.idInput');
  }
}
