export default class AddressAutocomplete {
  static initialize(addressBlock = null) {
    new this(addressBlock);
  }

  constructor(addressBlock = null) {
    this.addressBlocks = this.initializeAddressBlock(addressBlock)

    this.addressBlocks.forEach((addressBlock) => {
      const autocompleteInput = this.findAutocompleteInput(addressBlock);
      const autocomplete = this.initAutocomplete(autocompleteInput);
      autocompleteInput.addEventListener("focus", () => this.geolocate(autocomplete));
      autocompleteInput.addEventListener("keydown", this.preventSubmitFormOnPressEnter.bind(this));
    });
  }

  initializeAddressBlock(addressBlock) {
    if (addressBlock) return [addressBlock]

    return Array.from(document.querySelectorAll('.addressAutocomplete'));
  }

  initAutocomplete(autocompleteInput) {
    const autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      { types: ["geocode"] }
    );
    autocomplete.setFields(["address_component"]);
    autocomplete.addListener("place_changed", () => {
      this.fillInAddress(autocomplete, autocompleteInput.closest('.addressAutocomplete'));
    });

    return autocomplete;
  }

  fillInAddress(autocomplete, addressBlock) {
    const place = autocomplete.getPlace();
    const { address_components } = place;
    if(!address_components) return;

    this.fillInStreet(address_components, addressBlock);
    this.fillInCity(address_components, addressBlock);
    this.fillInZip(address_components, addressBlock);
    this.fillInState(address_components, addressBlock);
  }

  fillInStreet(addressComponents, addressBlock) {
    const streetNumber = addressComponents.find((component) => component.types[0] === 'street_number');
    const route = addressComponents.find((component) => component.types[0] === 'route');

    const streetInput = addressBlock.querySelector('.street')

    if (!route) {
      streetInput.value = '';
      return;
    }

    const streetAddress = [streetNumber ? streetNumber.short_name : '', route.long_name].join(' ');

    streetInput.value = streetAddress;
  }

  fillInCity(addressComponents, addressBlock) {
    let city = addressComponents.find((component) => component.types[0] === 'locality');
    city ||= addressComponents.find((component) => component.types[0] === 'sublocality_level_1');
    city ||= addressComponents.find((component) => component.types[0] === 'administrative_area_level_3');
    city ||= addressComponents.find((component) => component.types[0] === 'administrative_area_level_2');
    city ||= addressComponents.find((component) => component.types[0] === 'administrative_area_level_1');

    if (!city) return;

    addressBlock.querySelector('.city').value = city.long_name;
  }

  fillInZip(addressComponents, addressBlock) {
    const zip = addressComponents.find((component) => component.types[0] === 'postal_code');

    if (!zip) return;

    addressBlock.querySelector('.zip').value = zip.short_name;
  }

  fillInState(addressComponents, addressBlock) {
    const state = addressComponents.find((component) => component.types[0] === 'administrative_area_level_1');

    if (!state) return;

    const stateSelect = addressBlock.querySelector('.state');

    const option = [...stateSelect.options].find((option) => option.text === state.short_name);

    if (option) {
      stateSelect.value = option.value;
      const evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      stateSelect.dispatchEvent(evt)
    }
  }

  geolocate(autocomplete) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  findAutocompleteInput(addressBlock) {
    return addressBlock.querySelector('.autocompleteInput');
  }

  preventSubmitFormOnPressEnter(e) {
    if (e.code !== 'Enter') return;

    e.preventDefault();
  }
}
