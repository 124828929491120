import CalculatePercentageConnectedMicrogridDocuments from 'pages/ConnectedMicrogrids/shared/CalculatePercentageConnectedMicrogridDocuments';
import { refreshPage } from 'utils/routing';

export default class ConnectedMicrogridDocumentSubmitCallback {
  constructor(pageContent, form) {
    this.pageContent = pageContent;
    this.form = form;

    const connectedMicrogridDocuments = this.pageContent.querySelectorAll('.connectedMicrogridDocumentForm');
    const percents = new CalculatePercentageConnectedMicrogridDocuments(connectedMicrogridDocuments).result();

    if (percents == 100) refreshPage();
  }
}
