import PhoneInput from 'components/PhoneInput';
import AddressAutocomplete from 'components/AddressAutocomplete';
import Tabs from 'components/Tabs';
import NaicsSelect from 'components/NaicsSelect';

export default class Components {
  static initialize() {
    PhoneInput.initialize();
    AddressAutocomplete.initialize();
    Tabs.initialize();
    NaicsSelect.initialize();
  }
}
