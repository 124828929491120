import TabsHandler from './TabsHandler';
import ProgressBarHandler from './ProgressBarHandler';
import DatepickerHandler from './DatepickerHandler';

export default class PowerPlans {
  static initialize() {
    const pageContent = document.querySelector('#retails-power-plans');

    if (!pageContent) return;

    new TabsHandler(pageContent);
    new ProgressBarHandler(pageContent);
    new DatepickerHandler(pageContent);
  }
}
