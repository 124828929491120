import { Controller } from "stimulus"
import DatepickerHandler from 'pages/Retails/PowerPlans/DatepickerHandler'
import removeSelectOptions from 'utils/select_utilities'

export default class extends Controller {
    static targets = ['quote_start_date', 'quote_start_date_div', 'term_select']

    quote_start_date_divTargetConnected(target) {
        if(target.getAttribute('data-datepicker-target') === 'quote_start_date_div') {
            new DatepickerHandler(target);
        }
    }

    start_date_changed(event) {
        const selectTarget = this.term_selectTarget
        const dateInputValue = this.quote_start_dateTarget.value;
        removeSelectOptions(selectTarget)

        if (dateInputValue) {
            jQuery.ajax({
                url: "/brokers/end_date?start_date=" + dateInputValue,
                type: "GET",
                dataType: "json",
                success: function(data){
                    data.forEach((month) => {
                        const option = document.createElement('option');
                        option.value = month;
                        option.text = month;
                        selectTarget.appendChild(option);
                    })
                }
            });
        }
    }
}
