import Sortable from "sortablejs";

export default class SubmitFormHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    this.pageContent.querySelector('.submitFormButton').addEventListener('click', this.handleSubmitClick.bind(this));
  }

  handleSubmitClick(e) {
    e.preventDefault();

    if (this.allLoaAccepted()) {
      e.target.form.submit();
    } else {
      this.showSubmitErrorMessage();
    }
  }

  allLoaAccepted() {
    return [...this.pageContent.querySelectorAll('.acceptLOACheckbox')].every((checkbox) => checkbox.checked);
  }

  showSubmitErrorMessage() {
    this.pageContent.querySelector('.submitErrorMessage').classList.add('d-block');
  }
}
