export default class Tabs {
  static initialize() {
    new this();
  }

  constructor() {
    document.body.addEventListener('click', this.handleTabButtonClick.bind(this));
  }

  handleTabButtonClick(e) {
    const button = e.target;
    const tabButton = button.classList.contains('tabButton') ? button : button.closest('.tabButton')
    if (!tabButton) return;

    const container = this.container(tabButton);
    this.changeTabPane(container, tabButton);
  }

  container(tabButton) {
    return tabButton.closest('.tabs');
  }

  changeTabPane(container, button) {
    const target = button.dataset.target;
    const activeButton = container.querySelector('.tabButton.active');
    const activePane = container.querySelector('.tabPane.show');

    if (activeButton) activeButton.classList.remove('active');

    button.classList.add('active');

    if (activePane) activePane.classList.remove('show');

    if (target) container.querySelector(target).classList.add('show');
  }
}
