import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        this.company_changed()
    }

    company_changed() {
        let any_company_visible = false
        document.querySelectorAll('.broker-companies > .broker-company').forEach((div)=>
        {
           if(!div.classList.contains('d-none')) {
                div.classList.add('d-none')
            }
        })
        const company_id = this.targets.find("company_name").value
        const company_div = document.querySelector(`#company-${company_id}`)
        if (company_div) {
            company_div.classList.remove('d-none')
            any_company_visible = true
        }
        this._toggle_initial_menu(any_company_visible)
    }

    _toggle_initial_menu(any_company_visible) {
        let ele = document.querySelector('.broker-initial-menu')
        ele && (ele.hidden = any_company_visible)
    }
}