export default class ConnectedMicrogridDisabledCheckboxHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.connectedMicrogridDisabledCheckbox')].forEach(
      checkbox => checkbox.addEventListener('change', this.handleChackboxChange.bind(this))
    )
  }

  handleChackboxChange(e) {
    e.target.form.submit();
  }
}
