import ValuePropsSortingHandler from './ValuePropsSortingHandler'
import ConnectedMicrogridDocumentSubmitCallback from './ConnectedMicrogridDocumentSubmitCallback';
import FileUploadButtonHandler from 'pages/ConnectedMicrogrids/shared/FileUploadButtonHandler';
import AllowNaButtonHandler from 'pages/ConnectedMicrogrids/shared/AllowNaButtonHandler';
import ConnectedMicrogridDocumentFormHandler from 'pages/ConnectedMicrogrids/shared/ConnectedMicrogridDocumentFormHandler';

export default class CompanyInformation {
  static initialize() {
    const pageContent = document.querySelector('#connected-microgrids-company-information-index');

    if (!pageContent) return;

    new ValuePropsSortingHandler(pageContent)
    new FileUploadButtonHandler(pageContent);
    new AllowNaButtonHandler(pageContent);
    new ConnectedMicrogridDocumentFormHandler(
      pageContent,
      ConnectedMicrogridDocumentSubmitCallback
    );
  }
}
