export default class FileUploadButtonHandler {
  constructor(pageContent) {
    this.pageContent = pageContent;

    [...this.pageContent.querySelectorAll('.fileUploadField')].forEach(
      fileUploadField => fileUploadField.addEventListener('change', this.handleFileUploadChange.bind(this))
    )
  }

  handleFileUploadChange(e) {
    const target = e.target;

    const container = target.closest('.connected-microgrid-document-form');
    const label = target.closest('label');
    const filenameRow = container.querySelector('.filename');
    const file = target.files[0];

    filenameRow.innerText = file ? file.name : '';

    if (!!file) {
      label.classList.remove('btn-outline-orange');
      label.classList.add('btn-outline-dark-blue');
      filenameRow.innerText = file.name;
    } else {
      label.classList.add('btn-outline-orange');
      label.classList.remove('btn-outline-dark-blue');
      filenameRow.innerText = '';
    }
  }
}
