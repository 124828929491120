import FormHandlerHelpers from "./FormHandlerHelpers";

export default class SupplierFormHandler {
    constructor(pageContent) {
        this.pageContent = pageContent;
        this.render()
    }
    render() {
        FormHandlerHelpers.bindToAllOnChange(this.pageContent, '.repcontract-other',
            this.handleRepContractOtherClick)
        FormHandlerHelpers.bindToAllOnChange(this.pageContent, '.repcontract-fixed',
            this.handleRepContractOtherClick)
        FormHandlerHelpers.bindToAllOnChange(this.pageContent, '.repcontract-mtm',
            this.handleRepContractMtMClick)
        this.updatePage()
    }
    updatePage(ignored) {
        const divs = this.pageContent.querySelectorAll('div.repcontract-info')
        let i;
        for (i = 0; i < divs.length; i++) {
            const div = divs[i];
            this.handleRepContractOtherClick({'target': div.querySelector('input.repcontract-other')})
            this.handleRepContractMtMClick({'target': div.querySelector('input.repcontract-mtm')})
        }
    }
    handleRepContractOtherClick(e) {
        const supplierInfoDiv = e.target.closest('.repcontract-info')
        const isFixed = supplierInfoDiv.querySelector('input.repcontract-fixed').checked
        const isMtm = supplierInfoDiv.querySelector('input.repcontract-mtm').checked
        FormHandlerHelpers.toggleDivClearValue(e.target, supplierInfoDiv, '.repcontract-rate', isFixed)
        FormHandlerHelpers.toggleDivClearValue(e.target, supplierInfoDiv, '.repcontract-mtm', !isFixed)
        FormHandlerHelpers.toggleDivClearValue(e.target, supplierInfoDiv, '.repcontract-term_end', isFixed || !isMtm)
    }
    handleRepContractMtMClick(e) {
        const supplierInfoDiv = e.target.closest('.repcontract-info')
        const display = !supplierInfoDiv.querySelector('input.repcontract-mtm').checked
        FormHandlerHelpers.toggleDivClearValue(e.target, supplierInfoDiv, '.repcontract-term_end', display)
    }
}